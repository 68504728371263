import {
  ACT_MANAGEMENT_LOCAL_CREATE_PATH,
  ACT_MANAGEMENT_PATH,
  ADDITIONAL_SERVICES_SETTINGS_CREATE_PATH,
  ADDITIONAL_SERVICES_SETTINGS_ITEM_PATH,
  ADDITIONAL_SERVICES_SETTINGS_LIST_PATH,
  ADDRESS_BOOK_CREATE_NEW_PATH,
  ADDRESS_BOOK_CREATE_PATH,
  ADDRESS_BOOK_ITEM_NEW_PATH,
  ADDRESS_BOOK_ITEM_PATH,
  ADDRESS_BOOK_LIST_NEW_PATH,
  ADDRESS_BOOK_LIST_PATH,
  ADMIN_AREAS_PATH,
  ARCHIVE_ORDER_LIST_PATH,
  AREAS_PATH,
  BAD_ADDRESS_LIST_PATH,
  BANK_LIST_PATH,
  BANK_SETTINGS_LIST_PATH,
  BATCH_CATEGORIES_LIST_PATH,
  CALL_STEP_SETTINGS_LIST_PATH,
  CASH_CERTIFICATE_PATH,
  CHARTS_PATH,
  COMMUNICATION_SETTINGS_CREATE_PATH,
  COMMUNICATION_SETTINGS_ITEM_PATH,
  COMMUNICATION_SETTINGS_LIST_PATH,
  CUSTOM_BATCH_UPDATE_PATH,
  CUSTOMER_CREATE_PATH,
  CUSTOMER_FEEDBACK_LIST_PATH,
  CUSTOMER_ITEM_PATH,
  CUSTOMER_LIST_PATH,
  CUSTOMER_MERCHANT_ITEM_PATH,
  CUSTOMS_CHECKOUT_PATH,
  DEFAULT_PACKAGE_NEW_PATH,
  DISPATCH_ITEM_PATH,
  DRIVER_LIABILITY_PATH,
  DRIVER_LIST_PATH,
  DRIVER_ORDER_RADAR_PATH,
  DRIVER_RADAR_PATH,
  DRIVER_REPORTS_PATH,
  DRIVERS_ATTENDANCE_PATH,
  DRIVERS_DASHBOARD_PATH,
  FINANCE_BANK_DEPOSIT_ITEM_PATH,
  FINANCE_BANK_DEPOSIT_ORDERS_PATH,
  FINANCE_BANK_DEPOSITS_PATH,
  FINANCE_CASHIERS_PATH,
  FINANCE_ORDER_EXCEPTIONS_PATH,
  FINANCE_ORDER_LIST_PATH,
  FINANCE_SETTLEMENT_PATH,
  FINANCE_TRANSACTIONS_PATH,
  FINANCE_TRANSFER_ITEM_PATH,
  FINANCE_TRANSFER_LIST_PATH,
  FINANCE_WITHDRAW_ITEM_PATH,
  FINANCE_WITHDRAW_LIST_PATH,
  HOLIDAY_SETTINGS_PATH,
  HYBRID_PATH,
  INTERNATIONAL_CHARTS_PATH,
  INTERNATIONAL_EMPTY_BAGS_PATH,
  INTERNATIONAL_IN_TARIFF_CREATE_PATH,
  INTERNATIONAL_IN_TARIFF_ITEM_PATH,
  INTERNATIONAL_IN_TARIFF_PATH,
  INTERNATIONAL_LETTER_PATH,
  INTERNATIONAL_OUT_TARIFF_CREATE_PATH,
  INTERNATIONAL_OUT_TARIFF_ITEM_PATH,
  INTERNATIONAL_OUT_TARIFF_PATH,
  INTERNATIONAL_PARCEL_PATH,
  INTERNATIONAL_SETTINGS_AIRPORT_PATH,
  INTERNATIONAL_SETTINGS_COUNTRIES_PATH,
  INTERNATIONAL_SETTINGS_FLIGHTS_PATH,
  INTERNATIONAL_SETTINGS_FORMAT_PATH,
  INTERNATIONAL_SETTINGS_MAIL_CLASS_PATH,
  INTERNATIONAL_SETTINGS_PATH,
  INTERNATIONAL_SETTINGS_SUB_CLASSES_PATH,
  INTERNATIONAL_SETTINGS_TYPES_PATH,
  IPS_BATCH_SYNC_PATH,
  JOBS_PATH,
  JURISDICTION_MANAGEMENT_PATH,
  LETTERS_PATH,
  LOCKER_POST_CREATE_PATH,
  LOCKER_POST_ITEM_PATH,
  LOCKER_POST_LIST_PATH,
  NEW_CUSTOM_BATCH_UPDATE_PATH,
  OPERATOR_BALANCE_PATH,
  OPERATOR_BALANCE_REFUND_PATH,
  OPIS_CATEGORIES_LIST_PATH,
  ORDER_ACTIVITY_HISTORY_PATH,
  ORDER_BATCH_UPDATE_PATH,
  ORDER_BIN_VALIDATION_SIMPLE_FIREBASE_VIEW_PATH,
  ORDER_BIN_VALIDATION_SIMPLE_VIEW_PATH,
  ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL,
  ORDER_BIN_VALIDATION_VIEW_PATH,
  ORDER_CUSTOMS_FEE_MANAGEMENT_LIST_VIEW_PATH,
  ORDER_CUSTOMS_INBOUND_SORTING_VIEW_PATH,
  ORDER_CUSTOMS_LIST_VIEW_PATH,
  ORDER_CUSTOMS_ORDERS_LIST_VIEW_PATH,
  ORDER_CUSTOMS_OUTBOUND_SORTING_VIEW_PATH,
  ORDER_JOBS_PATH,
  ORDER_LIST_MAP_PATH,
  ORDER_LIST_PATH,
  ORDER_LIST_REQUIRED_ATTENTION_PATH,
  ORDER_LIST_URL,
  ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_PATH,
  ORDER_OUTBOUND_CONSOLIDATED_FIREBASE_VIEW_PATH,
  ORDER_OUTBOUND_CONSOLIDATED_VIEW_PATH,
  ORDER_OUTBOUND_CONTAINER_FIREBASE_VIEW_PATH,
  ORDER_OUTBOUND_CONTAINER_VIEW_PATH,
  ORDER_OUTBOUND_REGISTRY_SORTING_FIREBASE_VIEW_URL,
  ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_PATH,
  ORDER_OUTBOUND_ROUTES_FIREBASE_VIEW_PATH,
  ORDER_OUTBOUND_ROUTES_OFFLINE_VIEW_PATH,
  ORDER_OUTBOUND_ROUTES_VIEW_PATH,
  ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_PATH,
  ORDER_OUTBOUND_SORTING_VIEW_PATH,
  ORDER_RETURN_VIEW_PATH,
  ORDER_RULE_CREATE_PATH,
  ORDER_RULE_ITEM_PATH,
  ORDER_RULE_LIST_PATH,
  ORDER_RULES_GROUP_ITEM_PATH,
  ORDER_RULES_GROUP_LIST_PATH,
  ORDER_SORTING_MULTIBOX_VIEW_PATH,
  ORDER_TAGS_LIST_PATH,
  ORDER_TRACK_PATH,
  ORDER_UPLOAD_PATH,
  ORDER_UPLOAD_V2_PATH,
  ORDERS_PER_POSTCODES_PATH,
  PACKAGE_RULE_CREATE_PATH,
  PACKAGE_RULE_ITEM_PATH,
  PACKAGE_RULE_LIST_PATH,
  PDF_SETTINGS_PATH,
  PDF_TEMPLATE_CREATE_HTML_PATH,
  PDF_TEMPLATE_CREATE_PATH,
  PDF_TEMPLATE_HTML_ITEM_PATH,
  PDF_TEMPLATE_ITEM_PATH,
  PI1_PATH,
  POST_CREATE_PATH,
  POST_ITEM_PATH,
  POSTCODES_COORDINATES_PATH,
  POSTCODES_CREATE_PATH,
  POSTCODES_ITEM_PATH,
  POSTCODES_LIST_PATH,
  PROMO_CREATE_PATH,
  PROMO_ITEM_PATH,
  PROMO_LIST_PATH,
  RECEPTACLE_ITEM_PATH,
  REPORT_WAREHOUSE_PATH,
  REPORTING_FINANCIAL_PATH,
  REPORTING_OPERATIONAL_PATH,
  REPORTING_ROOT_PATH,
  REPORTS_CN_38_PATH,
  REPORTS_CREATE_CN_38_PATH,
  REPORTS_EDIT_CN_38_PATH,
  ROUTE_ITEM_PATH,
  ROUTE_TEMPLATE_CREATE_PATH,
  ROUTE_TEMPLATE_ITEM_PATH,
  ROUTE_TEMPLATES_PATH,
  SAMPLE_ITEM_CREATE_PATH,
  SAMPLE_ITEM_CREATE_PATH_WITH_SETTINGS,
  SAMPLE_ITEM_PATH,
  SAMPLE_ITEM_PATH_WITH_SETTINGS,
  SAMPLE_ITEMS_LIST_PATH,
  SAMPLE_ITEMS_SETTINGS_PATH,
  SAMPLE_ORDER_ITEM_LIST_PATH,
  SAMPLE_ORDER_ITEM_PATH,
  SAMPLE_ORDER_LIST_PATH,
  SCORE_SETTINGS_PATH,
  SERVICE_TYPES_LIST_PATH,
  SETTINGS_DASHBOARD_PATH,
  SETTINGS_MAP_PATH,
  SETTINGS_MARKETPLACE_DETAILS_URL,
  SETTINGS_MARKETPLACE_ITEM_PATH,
  SETTINGS_MARKETPLACES_PATH,
  SETTINGS_ORDER_PATH,
  SETTINGS_PRICING_PLANS_CREATE_PATH,
  SETTINGS_PRICING_PLANS_ITEM_PATH,
  SETTINGS_PRICING_PLANS_PATH,
  SETTINGS_SMS_PROVIDER_PATH,
  SHIFTING_HISTORY_PATH,
  SHOP_ADDRESS_CREATE_PATH,
  SHOP_ADDRESS_ITEM_PATH,
  SHOP_ADDRESS_LIST_PATH,
  SHOP_CATEGORY_LIST_PATH,
  SHOP_CITY_LIST_PATH,
  SHOP_MENU_CREATE_PATH,
  SHOP_MENU_ITEM_PATH,
  SHOP_MENU_LIST_PATH,
  SHOP_PRODUCT_CREATE_PATH,
  SHOP_PRODUCT_ITEM_PATH,
  SHOP_PRODUCT_LIST_PATH,
  SHOP_VENUE_CREATE_PATH,
  SHOP_VENUE_ITEM_PATH,
  SHOP_VENUE_LIST_PATH,
  SHOP_VENUE_MENU_LIST_PATH,
  SIMPLE_POST_CREATE_PATH,
  SINGLE_SIMPLE_POST_CREATE_PATH,
  SLA_RULE_CREATE_PATH,
  SLA_RULE_ITEM_PATH,
  SLA_RULE_LIST_PATH,
  SUBSCRIPTION_BILLING_PATH,
  SUBSCRIPTION_PATH,
  SUBSCRIPTION_SIGN_UP_PATH,
  SUPPLIER_CONTACTS_LIST_PATH,
  SUPPLIER_FINANCE_SETTINGS_PATH,
  SUPPLIER_ITEM_PATH,
  SUPPLIER_LIST_PATH,
  SUPPLIER_USER_LIST_PATH,
  TERMS_SETTINGS_CREATE_PATH,
  TERMS_SETTINGS_ITEM_PATH,
  TERMS_SETTINGS_LIST_PATH,
  TIMESLOT_AVAILABILITY_LIST_PATH,
  TIMESLOT_LIST_PATH,
  USERS_LIST_PATH,
  USERS_PERMISSION_FORM_PATH,
  USERS_PERMISSION_LIST_PATH,
  VEHICLE_LIST_PATH,
  VOICE_SORTING_PATH,
  WAREHOUSE_CREATE_PATH,
  WAREHOUSE_ITEM_PATH,
  WAREHOUSE_LIST_PATH,
  WAREHOUSE_REPORTS_PATH,
} from "../constants/AdminPathConstants";
import {
  CHANGE_PASSWORD_PATH,
  DASHBOARD_PATH,
  PROFILE_PATH,
  RECOVER_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
} from "../constants/DashboardPathConstants";
import AdminTermsEditDialogWrapper from "../wrappers/admin/AdminTermsEditDialogWrapper";
import AdminTermsCreateDialogWrapper from "../wrappers/admin/AdminTermsCreateDialogWrapper";
import SignOut from "../components/auth/SignOut";
import AdminActivityHistoryListContainer from "../components/admin/AdminActivityHistoryListContainer";
import AdminRoot from "../containers/admin/AdminRoot";
import AdminSignIn from "../containers/admin/AdminSignIn";
import SMSSettings from "../containers/admin/SMSSettings";
import AdminProfile from "../containers/admin/AdminProfile";
import AdminPostItem from "../containers/admin/AdminPostItem";
import AdminOrderList from "../containers/admin/AdminOrderList";
import AdminPromoItem from "../containers/admin/AdminPromoItem";
import AdminPromoList from "../containers/admin/AdminPromoList";
import AdminDriverList from "../containers/admin/AdminDriverList";
import AdminPostCreate from "../containers/admin/AdminPostCreate";
import AdminPromoCreate from "../containers/admin/AdminPromoCreate";
import AdminVehicleList from "../containers/admin/AdminVehicleList";
import AdminCashiersList from "../containers/admin/AdminCashiersList";
import AdminCustomerList from "../containers/admin/AdminCustomerList";
import AdminOrderJobList from "../containers/admin/AdminOrderJobList";
import AdminShopCityList from "../containers/admin/AdminShopCityList";
import AdminShopMenuItem from "../containers/admin/AdminShopMenuItem";
import AdminShopMenuList from "../containers/admin/AdminShopMenuList";
import AdminSupplierItem from "../containers/admin/AdminSupplierItem";
import AdminSupplierList from "../containers/admin/AdminSupplierList";
import AdminTimeSlotList from "../containers/admin/AdminTimeSlotList";
import AdminShopVenueItem from "../containers/admin/AdminShopVenueItem";
import AdminShopVenueList from "../containers/admin/AdminShopVenueList";
import AdminVenueMenuList from "../containers/admin/AdminVenueMenuList";
import AdminWarehouseItem from "../containers/admin/AdminWarehouseItem";
import AdminWarehouseList from "../containers/admin/AdminWarehouseList";
import AdminRadarContainer from "../containers/admin/AdminRadarContainer";
import AdminShopMenuCreate from "../containers/admin/AdminShopMenuCreate";
import AdminAddressBookItem from "../containers/admin/AdminAddressBookItem";
import AdminAddressBookList from "../containers/admin/AdminAddressBookList";
import AdminPdfSettingsList from "../containers/admin/AdminPdfSettingsList";
import AdminPdfTemplateItem from "../containers/admin/AdminPdfTemplateItem";
import AdminReportContainer from "../containers/admin/AdminReportContainer";
import AdminShopVenueCreate from "../containers/admin/AdminShopVenueCreate";
import AdminWarehouseCreate from "../containers/admin/AdminWarehouseCreate";
import AdminDriversDashboard from "../containers/admin/AdminDriversDashboard";
import AdminShopCategoryList from "../containers/admin/AdminShopCategoryList";
import AdminSupplierUserList from "../containers/admin/AdminSupplierUserList";
import AdminTagListContainer from "../containers/admin/AdminTagListContainer";
import AdminVenueAddressItem from "../containers/admin/AdminVenueAddressItem";
import AdminVenueAddressList from "../containers/admin/AdminVenueAddressList";
import AdminVenueProductItem from "../containers/admin/AdminVenueProductItem";
import AdminVenueProductList from "../containers/admin/AdminVenueProductList";
import AdminAddressBookCreate from "../containers/admin/AdminAddressBookCreate";
import AdminBankListContainer from "../containers/admin/AdminBankListContainer";
import AdminPostcodesItem from "../containers/admin/AdminPostcodesItem";
import AdminPostcodesList from "../containers/admin/AdminPostcodesList";
import AdminNewAreasContainer from "../containers/admin/AdminNewAreasContainer";
import AdminPdfTemplateCreate from "../containers/admin/AdminPdfTemplateCreate";
import AdminTermsSettingsList from "../containers/admin/AdminTermsSettingsList";
import AdminAddressBookItemNew from "../containers/admin/AdminAddressBookItemNew";
import AdminAddressBookListNew from "../containers/admin/AdminAddressBookListNew";
import AdminOrderJobsContainer from "../containers/admin/AdminOrderJobsContainer";
import AdminUsersListContainer from "../containers/admin/AdminUsersListContainer";
import AdminVenueAddressCreate from "../containers/admin/AdminVenueAddressCreate";
import AdminVenueProductCreate from "../containers/admin/AdminVenueProductCreate";
import AdminHtmlPdfTemplateItem from "../containers/admin/AdminHtmlPdfTemplateItem";
import AdminPostcodesCreate from "../containers/admin/AdminPostcodesCreate";
import AdminAddressBookCreateNew from "../containers/admin/AdminAddressBookCreateNew";
import AdminCallStepSettingsList from "../containers/admin/AdminCallStepSettingsList";
import AdminCustomerFeedbackList from "../containers/admin/AdminCustomerFeedbackList";
import AdminMapSettingsContainer from "../containers/admin/AdminMapSettingsContainer";
import AdminOrderReturnContainer from "../containers/admin/AdminOrderReturnContainer";
import AdminOrderRouterContainer from "../containers/admin/AdminOrderRouterContainer";
import AdminSupplierContactsList from "../containers/admin/AdminSupplierContactsList";
import AdminHtmlPdfTemplateCreate from "../containers/admin/AdminHtmlPdfTemplateCreate";
import AdminUploadOrdersContainer from "../containers/admin/AdminUploadOrdersContainer";
import AdminFinanceReportContainer from "../containers/admin/AdminFinanceReportContainer";
import AdminOrderRuleItemContainer from "../containers/admin/AdminOrderRuleItemContainer";
import AdminOrderRuleListContainer from "../containers/admin/AdminOrderRuleListContainer";
import AdminScoreSettingsContainer from "../containers/admin/AdminScoreSettingsContainer";
import AdminSettingsOrderContainer from "../containers/admin/AdminSettingsOrderContainer";
import AdminSampleItemEditContainer from "../containers/admin/AdminSampleItemEditContainer";
import AdminUploadOrdersV2Container from "../containers/admin/AdminUploadOrdersV2Container";
import AdminCODTransferItemContainer from "../containers/admin/AdminCODTransferItemContainer";
import AdminCODTransferListContainer from "../containers/admin/AdminCODTransferListContainer";
import AdminCODWithdrawItemContainer from "../containers/admin/AdminCODWithdrawItemContainer";
import AdminCODWithdrawListContainer from "../containers/admin/AdminCODWithdrawListContainer";
import AdminDriverLiabilityContainer from "../containers/admin/AdminDriverLiabilityContainer";
import AdminHolidaySettingsContainer from "../containers/admin/AdminHolidaySettingsContainer";
import AdminOrderExceptionsContainer from "../containers/admin/AdminOrderExceptionsContainer";
import AdminOrderRuleCreateContainer from "../containers/admin/AdminOrderRuleCreateContainer";
import AdminPackageRuleItemContainer from "../containers/admin/AdminPackageRuleItemContainer";
import AdminPackageRuleListContainer from "../containers/admin/AdminPackageRuleListContainer";
import AdminSampleItemsListContainer from "../containers/admin/AdminSampleItemsListContainer";
import AdminTimeslotAvailabilityList from "../containers/admin/AdminTimeslotAvailabilityList";
import AdminCommunicationSettingsItem from "../containers/admin/AdminCommunicationSettingsItem";
import AdminCommunicationSettingsList from "../containers/admin/AdminCommunicationSettingsList";
import AdminFinanceOrderListContainer from "../containers/admin/AdminFinanceOrderListContainer";
import AdminSampleItemCreateContainer from "../containers/admin/AdminSampleItemCreateContainer";
import AdminDefaultPackageContainerNew from "../containers/admin/AdminDefaultPackageContainerNew";
import AdminDriversAttendanceContainer from "../containers/admin/AdminDriversAttendanceContainer";
import AdminFinanceSettlementContainer from "../containers/admin/AdminFinanceSettlementContainer";
import AdminOperationalReportContainer from "../containers/admin/AdminOperationalReportContainer";
import AdminOrderTrackingToolContainer from "../containers/admin/AdminOrderTrackingToolContainer";
import AdminPackageRuleCreateContainer from "../containers/admin/AdminPackageRuleCreateContainer";
import AdminSettingsDashboardContainer from "../containers/admin/AdminSettingsDashboardContainer";
import AdminCommunicationSettingsCreate from "../containers/admin/AdminCommunicationSettingsCreate";
import AdminNewMarkerplaceAreasContainer from "../containers/admin/AdminNewMarkerplaceAreasContainer";
import AdminOrderRulesGroupItemContainer from "../containers/admin/AdminOrderRulesGroupItemContainer";
import AdminOrderRulesGroupListContainer from "../containers/admin/AdminOrderRulesGroupListContainer";
import AdminOrderSortingUzpostContainer from "../containers/admin/AdminOrderSortingUzpostContainer";
import AdminBankReconcileSettingsContainer from "../containers/admin/AdminBankReconcileSettingsContainer";
import AdminSampleItemSettinsEditContainer from "../containers/admin/AdminSampleItemSettinsEditContainer";
import AdminSlaManagementRuleItemContainer from "../containers/admin/AdminSlaManagementRuleItemContainer";
import AdminSlaManagementRuleListContainer from "../containers/admin/AdminSlaManagementRuleListContainer";
import AdminFinanceBankDepositItemContainer from "../containers/admin/AdminFinanceBankDepositItemContainer";
import AdminOrdersPerPostcodesContainer from "../containers/admin/AdminOrdersPerPostcodesContainer";
import AdminFinanceBankDepositsListContainer from "../containers/admin/AdminFinanceBankDepositsListContainer";
import AdminOrdersRequiredAttentionContainer from "../containers/admin/AdminOrdersRequiredAttentionContainer";
import AdminSampleItemsSettingsListContainer from "../containers/admin/AdminSampleItemsSettingsListContainer";
import AdminSlaManagementRuleCreateContainer from "../containers/admin/AdminSlaManagementRuleCreateContainer";
import AdminSupplierFinanceSettingsContainer from "../containers/admin/AdminSupplierFinanceSettingsContainer";
import AdminFinanceBankDepositContainer from "../containers/admin/AdminFinanceBankDepositOrdersContainer";
import AdminPostcodesCoordinatesContainer from "../containers/admin/AdminPostcodesCoordinatesContainer";
import AdminSampleItemSettingsCreateContainer from "../containers/admin/AdminSampleItemSettingsCreateContainer";
import AdminFinanceCustomerTransactionsContainer from "../containers/admin/AdminFinanceCustomerTransactionsContainer";
import ChangePasswordContainer from "../containers/shared/ChangePasswordContainer";
import RecoverPasswordContainer from "../containers/shared/RecoverPasswordContainer";
import AdminVoiceSortingContainer from "../containers/admin/AdminVoiceSortingContainer";
import Root from "../components/app-core/Root";
import NotFound from "../components/app-core/NotFound";
import { updateQuery } from "../../shared/helpers/UrlUtils";
import AdminBadAddressListContainer from "../containers/admin/bad-address/AdminBadAddressListContainer";
import AdminMarketplaceItem from "../containers/admin/marketplace/AdminMarketplaceItem";
import AdminMarketplaceListContainer from "../containers/admin/marketplace/AdminMarketplaceListContainer";
import AdminMarketplaceDetailsContainer from "../containers/admin/marketplace/AdminMarketplaceDetailsContainer";
import AdminSubscriptionContainer from "../containers/admin/subscription/AdminSubscriptionContainer";
import AdminPricingPlanItemContainer from "../containers/admin/subscription/AdminPricingPlanItemContainer";
import AdminPricingPlansListContainer from "../containers/admin/subscription/AdminPricingPlansListContainer";
import AdminPricingPlanCreateContainer from "../containers/admin/subscription/AdminPricingPlanCreateContainer";
import AdminSubscriptionPlansContainer from "../containers/admin/subscription/AdminSubscriptionPlansContainer";
import AdminSubscriptionSignUpContainer from "../containers/admin/subscription/AdminSubscriptionSignUpContainer";
import AdminServiceTypesListContainer from "../containers/admin/service-types/AdminServiceTypesListContainer";
import AdminPermissionListContainer from "../containers/admin/AdminPermissionsListContainer";
import AdminPermissionsForm from "../containers/admin/AdminPermissionsForm";
import AdminJurisdictionManagementContainer from "../containers/admin/AdminJurisdictionManagementContainer";
import AdminCashCertificateContainer from "../containers/admin/AdminCashCertificateContainer";
import AdminBinValidationContainer from "../containers/admin/AdminBinValidationContainer";
import AdminInboundSortingFirebaseContainer from "../containers/admin/firebase/AdminInboundSortingContainer";
import AdminNewRadarContainer from "../containers/admin/AdminNewRadarContainer";
import AdminOperatorBalanceContainer from "../containers/admin/AdminOperatorBalanceContainer";
import AdminOperatorRefundBalanceList from "../containers/admin/AdminOperatorRefundBalanceList";
import AdminOrderOutboundSortingContainer from "../containers/admin/AdminOrderOutboundSortingContainer";
import AdminOrderOutboundSortingFirebaseContainer from "../containers/admin/firebase/AdminOrderOutboundSortingContainer";
import AdminOrderOutboundRegistrySortingContainer from "../containers/admin/AdminOrderOutboundRegistrySortingContainer";
import AdminOrderOutboundRegistrySortingFirebaseContainer from "../containers/admin/firebase/AdminOrderOutboundRegistrySortingContainer";
import AdminAdditionalServicesList from "../containers/admin/AdminAdditionalServicesList";
import AdditionalServiceEditWrapper from "../wrappers/admin/AdditionalServiceEditWrapper";
import AdditionalServiceAddWrapper from "../wrappers/admin/AdditionalServiceAddWrapper";
import AdminOrderBatchUpdateContainerWrapper from "../containers/admin/AdminOrderBatchUpdateContainerWrapper";
import AdminOrderOutboundContainerContainerWrapper from "../containers/admin/AdminOrderOutboundContainerContainerWrapper";
import AdminOrderOutboundContainerFirebaseContainerWrapper from "../containers/admin/firebase/AdminOrderOutboundContainerContainerWrapper";
import BatchRouteContainer from "../containers/admin/BatchRouteContainer";
import RouteTemplateAddWrapper from "../wrappers/admin/RouteTemplateAddWrapper";
import RouteTemplateEditWrapper from "../wrappers/admin/RouteTemplateEditWrapper";
import AdminOrderOutboundRoutesContainerWrapper from "../containers/admin/AdminOrderOutboundRoutesContainerWrapper";
import AdminOrderOutboundRoutesFirebaseContainerWrapper from "../containers/admin/firebase/AdminOrderOutboundRoutesContainerWrapper";
import AdminOrderOutboundConsolidatedContainerWrapper from "../containers/admin/AdminOrderOutboundConsolidatedContainerWrapper";
import AdminOrderOutboundConsolidatedFirebaseContainerWrapper from "../containers/admin/firebase/AdminOrderOutboundConsolidatedContainerWrapper";
import RouteItem from "../wrappers/admin/RouteItem";
import AdminCustomsInboundContainer from "../containers/admin/AdminCustomsInboundContainer";
import AdminCustomsOutboundContainer from "../containers/admin/AdminCustomsOutboundContainer";
import AdminCustomsListContainer from "../containers/admin/AdminCustomsListContainer";
import AdminCustomsOrdersListContainer from "../containers/admin/AdminCustomsOrdersListContainer";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isMobile } from "react-device-detect";
import AdminBatchCategoriesList from "../containers/admin/AdminBatchCategoriesList";
import AdminCustomerCreate from "../containers/admin/AdminCustomerCreate";
import ReportWarehouse from "../containers/admin/ReportWarehouse";
import HybridContainer from "../containers/admin/HybridContainer";
import AdminSimpleOrderList from "../containers/admin/AdminSimpleOrderList";
import AdminSimplePostCreate from "../containers/admin/AdminSimplePostCreate";
import AdminSimpleOrderItemWrapper from "../containers/admin/AdminSimpleOrderItemWrapper";
import AdminSimpleOrderItemList from "../containers/admin/AdminSimpleOrderItemList";
import AdminSingleSimplePostCreate from "../containers/admin/AdminSingleSimplePostCreate";
import AdminOfflineBatchUpdateContainer from "../containers/admin/offline/AdminOfflineBatchUpdateContainer";
import AdminOfflineOutboundWrapper from "../containers/admin/offline/AdminOfflineOutboundWrapper";
import AdminIPSLettersList from "../containers/admin/AdminIPSLettersList";
import AdminDispatchItemList from "../containers/admin/AdminDispatchItemList";
import AdminReceptacleCreateWrapper from "../containers/admin/AdminReceptacleItemWrapper";
import AdminIPSPi1List from "../containers/admin/AdminIPSPi1List";
import AdminArchiveOrderList from "../containers/admin/AdminArchiveOrderList";
import AdminLockerPostList from "../containers/admin/AdminLockerPostList";
import AdminLockerpostCreate from "../containers/admin/AdminLockerpostCreate";
import AdminLockerPostItem from "../containers/admin/AdminLockerPostItem";
import AdminActManagementContainer from "../containers/admin/AdminActManagementContainer";
import ActManagementLocalCreateContainer from "../containers/admin/ActManagementLocalCreateContainer";
import AdminBatchHistoryUpdateList from "../containers/admin/AdminBatchHistoryUpdateList";
import AdminProductCategoriesList from "../containers/admin/AdminProductCategoriesList";
import AdminOfflineInboundSortingContainer from "../containers/admin/offline/AdminOfflineInboundSortingContainer";
import AdminShiftingBatchesList from "../containers/admin/AdminShiftingBatchesList";
import AdminDashboardContainer from "../containers/admin/AdminDashboardContainer";
import AdminInternationalDashboardContainer from "../containers/admin/AdminInternationalDashboardContainer";
import AdminInternationalSettingsContainer from "../containers/admin/AdminInternationalSettingsContainer";
import AdminInternationalSettingsCountriesContainer from "../containers/admin/AdminInternationalSettingsCountriesContainer";
import AdminInternationalSettingsFormatContainer from "../containers/admin/AdminInternationalSettingsFormatContainer";
import AdminInternationalSettingsSubclassesContainer from "../containers/admin/AdminInternationalSettingsSubclassesContainer";
import AdminInternationalSettingsTypeContainer from "../containers/admin/AdminInternationalSettingsTypeContainer";
import AdminInternationalLetterContainer from "../containers/admin/AdminInternationalLetterContainer";
import AdminInboundPackageContainer from "../containers/admin/AdminInboundPackageContainer";
import AdminInboundTariffUpdateContainer from "../containers/admin/AdminInboundTariffUpdateContainer";
import AdminInboundTariffCreateContainer from "../containers/admin/AdminInboundTariffCreateContainer";
import AdminOutboundTariffCreateContainer from "../containers/admin/AdminOutboundTariffCreateContainer";
import AdminOutboundTariffUpdateContainer from "../containers/admin/AdminOutboundTariffUpdateContainer";
import AdminOutboundTariffContainer from "../containers/admin/AdminOutboundTariffContainer";
import AdminInternationalParcelContainer from "../containers/admin/AdminInternationalParcelContainer";
import AdminInternationalEmptyBagsContainer from "../containers/admin/AdminInternationalEmptyBagsContainer";
import AdminCustomsPaymentListContainer from "../containers/admin/AdminCustomsPaymentListContainer";
import AdminCustomerItemDetailsContainer from "../containers/admin/AdminCustomerItemDetailsContainer";
import AdminCustomerMerchantItemDetailsContainer from "../containers/admin/AdminCustomerMerchantItemDetailsContainer";
import AdminBatchHistoryUpdateNewList from "../containers/admin/AdminBatchHistoryUpdateNewList";
import AdminDriverDashboardContainer from "../containers/admin/AdminDriverDashboardContainer";
import AdminWarehouseDashboardContainer from "../containers/admin/AdminWarehouseDashboardContainer";
import AdminIpsBatchSyncList from "../containers/admin/AdminIpsBatchSyncList";
import AdminCN38ReportsCreateContainer from "../containers/admin/AdminCN38ReportsCreateContainer";
import AdminCN38ReportsListContainer from "../containers/admin/AdminCN38ReportsListContainer";
import AdminCN38ReportsItemContainer from "../containers/admin/AdminCN38ReportsItemContainer";
import AdminInternationalSettingsFlightsContainer from "../containers/admin/AdminInternationalSettingsFlightsContainer";
import AdminInternationalSettingsMailClassContainer from "../containers/admin/AdminInternationalSettingsMailClassContainer";
import AdminInternationalAirportContainer from "../containers/admin/AdminInternationalAirportContainer";
import AdminInboundCustomsCheckoutListContainer from "../containers/admin/AdminInboundCustomsCheckoutListContainer";

export default {
  path: "/",
  component: Root,
  indexRoute: {
    onEnter: (state, replace) => {
      const link = isMobile
        ? updateQuery(ORDER_BIN_VALIDATION_SIMPLE_VIEW_URL)
        : updateQuery(ORDER_LIST_URL, state.location.query);
      return replace(link);
    },
  },
  childRoutes: [
    // Redirects.

    {
      path: "dashboard/order/list/all",
      onEnter: (state, redirect) =>
        redirect(updateQuery(ORDER_LIST_URL, state.location.query)),
    },
    {
      path: "dashboard/order/item/:id",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(ORDER_LIST_URL, {
            ...state.location.query,
            view: state.params.id,
          }),
        ),
    },

    // Auth.

    {
      path: SIGN_OUT_PATH,
      component: SignOut,
    },
    {
      path: RECOVER_PASSWORD_PATH,
      component: RecoverPasswordContainer,
    },
    {
      path: CHANGE_PASSWORD_PATH,
      component: ChangePasswordContainer,
    },
    {
      path: SIGN_IN_PATH,
      component: AdminSignIn,
    },
    {
      path: SUBSCRIPTION_SIGN_UP_PATH,
      component: AdminSubscriptionSignUpContainer,
    },
    {
      path: DASHBOARD_PATH,
      component: AdminRoot,
      indexRoute: {
        onEnter: (state, replace) =>
          replace(updateQuery(ORDER_LIST_URL, state.location.query)),
      },
      childRoutes: [
        // Profile.

        {
          path: PROFILE_PATH,
          component: AdminProfile,
        },
        {
          path: CHARTS_PATH,
          component: AdminDashboardContainer,
        },

        {
          path: INTERNATIONAL_CHARTS_PATH,
          component: AdminInternationalDashboardContainer,
        },

        {
          path: DRIVER_REPORTS_PATH,
          component: AdminDriverDashboardContainer,
        },

        {
          path: WAREHOUSE_REPORTS_PATH,
          component: AdminWarehouseDashboardContainer,
        },

        {
          path: SUBSCRIPTION_PATH,
          component: AdminSubscriptionContainer,
        },

        {
          path: SUBSCRIPTION_BILLING_PATH,
          component: AdminSubscriptionPlansContainer,
        },

        // Order.
        {
          path: ORDER_LIST_PATH,
          component: AdminOrderList,
        },
        {
          path: ARCHIVE_ORDER_LIST_PATH,
          component: AdminArchiveOrderList,
        },
        {
          path: SAMPLE_ORDER_LIST_PATH,
          component: AdminSimpleOrderList,
        },
        // {
        //   component: AdminAreasContainer,
        //   path: ADMIN_AREAS_PATH,
        // },
        {
          component: AdminNewAreasContainer,
          path: ADMIN_AREAS_PATH,
        },
        {
          component: AdminNewMarkerplaceAreasContainer,
          path: AREAS_PATH,
        },
        {
          component: AdminJurisdictionManagementContainer,
          path: JURISDICTION_MANAGEMENT_PATH,
        },
        {
          component: AdminBadAddressListContainer,
          path: BAD_ADDRESS_LIST_PATH,
        },
        {
          component: AdminOrderBatchUpdateContainerWrapper,
          path: ORDER_BATCH_UPDATE_PATH,
        },
        {
          component: RouteItem,
          path: ROUTE_ITEM_PATH,
        },

        {
          component: BatchRouteContainer,
          path: ROUTE_TEMPLATES_PATH,
        },
        {
          component: RouteTemplateAddWrapper,
          path: ROUTE_TEMPLATE_CREATE_PATH,
        },
        {
          component: RouteTemplateEditWrapper,
          path: ROUTE_TEMPLATE_ITEM_PATH,
        },
        {
          component: AdminSampleItemsSettingsListContainer,
          path: SAMPLE_ITEMS_SETTINGS_PATH,
        },
        {
          component: AdminSampleItemsListContainer,
          path: SAMPLE_ITEMS_LIST_PATH,
        },
        {
          component: AdminSampleItemCreateContainer,
          path: SAMPLE_ITEM_CREATE_PATH,
        },
        {
          component: AdminSampleItemSettingsCreateContainer,
          path: SAMPLE_ITEM_CREATE_PATH_WITH_SETTINGS,
        },
        {
          component: AdminSampleItemEditContainer,
          path: SAMPLE_ITEM_PATH,
        },
        {
          component: AdminSampleItemSettinsEditContainer,
          path: SAMPLE_ITEM_PATH_WITH_SETTINGS,
        },
        {
          component: AdminTagListContainer,
          path: ORDER_TAGS_LIST_PATH,
        },
        {
          path: ORDER_TRACK_PATH,
          component: AdminOrderTrackingToolContainer,
        },
        {
          path: ORDER_LIST_REQUIRED_ATTENTION_PATH,
          component: AdminOrdersRequiredAttentionContainer,
        },
        {
          path: ORDER_UPLOAD_PATH,
          component: AdminUploadOrdersContainer,
        },
        {
          path: ORDER_UPLOAD_V2_PATH,
          component: AdminUploadOrdersV2Container,
        },
        {
          path: ORDER_OUTBOUND_SORTING_VIEW_PATH,
          component: AdminOrderOutboundSortingContainer,
        },
        {
          path: ORDER_OFFLINE_OUTBOUND_SORTING_VIEW_PATH,
          component: AdminOfflineOutboundWrapper,
        },
        {
          path: ORDER_OUTBOUND_SORTING_FIREBASE_VIEW_PATH,
          component: AdminOrderOutboundSortingFirebaseContainer,
        },
        {
          path: ORDER_OUTBOUND_REGISTRY_SORTING_VIEW_PATH,
          component: AdminOrderOutboundRegistrySortingContainer,
        },
        {
          path: ORDER_OUTBOUND_REGISTRY_SORTING_FIREBASE_VIEW_URL,
          component: AdminOrderOutboundRegistrySortingFirebaseContainer,
        },
        {
          path: ORDER_OUTBOUND_CONSOLIDATED_VIEW_PATH,
          component: AdminOrderOutboundConsolidatedContainerWrapper,
        },
        {
          path: ORDER_OUTBOUND_CONSOLIDATED_FIREBASE_VIEW_PATH,
          component: AdminOrderOutboundConsolidatedFirebaseContainerWrapper,
        },
        {
          path: ORDER_OUTBOUND_CONTAINER_VIEW_PATH,
          component: AdminOrderOutboundContainerContainerWrapper,
        },
        {
          path: ORDER_OUTBOUND_CONTAINER_FIREBASE_VIEW_PATH,
          component: AdminOrderOutboundContainerFirebaseContainerWrapper,
        },
        {
          path: ORDER_OUTBOUND_ROUTES_VIEW_PATH,
          component: AdminOrderOutboundRoutesContainerWrapper,
        },
        {
          path: ORDER_OUTBOUND_ROUTES_FIREBASE_VIEW_PATH,
          component: AdminOrderOutboundRoutesFirebaseContainerWrapper,
        },
        {
          path: ORDER_OUTBOUND_ROUTES_OFFLINE_VIEW_PATH,
          component: AdminOfflineBatchUpdateContainer,
        },
        {
          path: ORDER_SORTING_MULTIBOX_VIEW_PATH,
          component: AdminOrderSortingUzpostContainer,
        },
        {
          path: ORDER_BIN_VALIDATION_VIEW_PATH,
          component: AdminBinValidationContainer,
        },
        {
          path: ORDER_BIN_VALIDATION_SIMPLE_VIEW_PATH,
          component: AdminOfflineInboundSortingContainer,
        },
        {
          path: ORDER_CUSTOMS_LIST_VIEW_PATH,
          component: AdminCustomsListContainer,
        },
        {
          path: ORDER_CUSTOMS_ORDERS_LIST_VIEW_PATH,
          component: AdminCustomsOrdersListContainer,
        },
        {
          path: ORDER_CUSTOMS_INBOUND_SORTING_VIEW_PATH,
          component: AdminCustomsInboundContainer,
        },
        {
          path: ORDER_CUSTOMS_OUTBOUND_SORTING_VIEW_PATH,
          component: AdminCustomsOutboundContainer,
        },
        {
          path: ORDER_BIN_VALIDATION_SIMPLE_FIREBASE_VIEW_PATH,
          component: AdminInboundSortingFirebaseContainer,
        },
        {
          path: VOICE_SORTING_PATH,
          component: AdminVoiceSortingContainer,
        },
        {
          path: ORDER_RETURN_VIEW_PATH,
          component: AdminOrderReturnContainer,
        },
        {
          path: ORDER_LIST_MAP_PATH,
          component: AdminOrderRouterContainer,
        },
        {
          path: ORDER_JOBS_PATH,
          component: AdminOrderJobsContainer,
        },
        {
          path: JOBS_PATH,
          component: AdminOrderJobList,
        },

        // Finance.

        {
          path: CASH_CERTIFICATE_PATH,
          component: AdminCashCertificateContainer,
        },
        {
          path: FINANCE_ORDER_LIST_PATH,
          component: AdminFinanceOrderListContainer,
        },
        {
          path: FINANCE_TRANSFER_LIST_PATH,
          component: AdminCODTransferListContainer,
        },
        {
          path: FINANCE_TRANSFER_ITEM_PATH,
          component: AdminCODTransferItemContainer,
        },
        {
          path: FINANCE_WITHDRAW_LIST_PATH,
          component: AdminCODWithdrawListContainer,
        },
        {
          path: FINANCE_WITHDRAW_ITEM_PATH,
          component: AdminCODWithdrawItemContainer,
        },
        {
          path: FINANCE_SETTLEMENT_PATH,
          component: AdminFinanceSettlementContainer,
        },
        {
          path: FINANCE_TRANSACTIONS_PATH,
          component: AdminFinanceCustomerTransactionsContainer,
        },
        {
          path: FINANCE_ORDER_EXCEPTIONS_PATH,
          component: AdminOrderExceptionsContainer,
        },
        {
          path: FINANCE_BANK_DEPOSIT_ORDERS_PATH,
          component: AdminFinanceBankDepositContainer,
        },
        {
          path: FINANCE_BANK_DEPOSITS_PATH,
          component: AdminFinanceBankDepositsListContainer,
        },
        {
          path: FINANCE_BANK_DEPOSIT_ITEM_PATH,
          component: AdminFinanceBankDepositItemContainer,
        },

        // Cashiers
        {
          path: FINANCE_CASHIERS_PATH,
          component: AdminCashiersList,
        },

        // Bank.
        {
          path: BANK_LIST_PATH,
          component: AdminBankListContainer,
        },
        {
          path: BANK_SETTINGS_LIST_PATH,
          component: AdminBankReconcileSettingsContainer,
        },

        // User.

        {
          path: USERS_LIST_PATH,
          component: AdminUsersListContainer,
        },

        // Customer.

        {
          path: CUSTOMER_LIST_PATH,
          component: AdminCustomerList,
        },
        {
          path: CUSTOMER_ITEM_PATH,
          component: AdminCustomerItemDetailsContainer,
        },
        {
          path: CUSTOMER_MERCHANT_ITEM_PATH,
          component: AdminCustomerMerchantItemDetailsContainer,
        },
        {
          path: CUSTOMER_CREATE_PATH,
          component: AdminCustomerCreate,
        },

        // Supplier.

        {
          path: SUPPLIER_LIST_PATH,
          component: AdminSupplierList,
        },
        {
          path: SUPPLIER_ITEM_PATH,
          component: AdminSupplierItem,
        },

        // Supplier User.

        {
          path: SUPPLIER_USER_LIST_PATH,
          component: AdminSupplierUserList,
        },

        // Supplier Contacts.

        {
          path: SUPPLIER_CONTACTS_LIST_PATH,
          component: AdminSupplierContactsList,
        },

        // Driver.

        {
          path: DRIVER_LIST_PATH,
          component: AdminDriverList,
        },
        {
          path: DRIVER_RADAR_PATH,
          component: AdminRadarContainer,
        },
        {
          path: DRIVER_ORDER_RADAR_PATH,
          component: AdminNewRadarContainer,
        },
        {
          path: DRIVERS_ATTENDANCE_PATH,
          component: AdminDriversAttendanceContainer,
        },
        {
          path: DRIVER_LIABILITY_PATH,
          component: AdminDriverLiabilityContainer,
        },
        {
          path: OPERATOR_BALANCE_PATH,
          component: AdminOperatorBalanceContainer,
        },
        {
          path: OPERATOR_BALANCE_REFUND_PATH,
          component: AdminOperatorRefundBalanceList,
        },
        {
          path: DRIVERS_DASHBOARD_PATH,
          component: AdminDriversDashboard,
        },

        // Vehicle.

        {
          path: VEHICLE_LIST_PATH,
          component: AdminVehicleList,
        },

        // c                                                                                                       .

        {
          path: WAREHOUSE_LIST_PATH,
          component: AdminWarehouseList,
        },
        {
          path: WAREHOUSE_ITEM_PATH,
          component: AdminWarehouseItem,
        },
        {
          path: WAREHOUSE_CREATE_PATH,
          component: AdminWarehouseCreate,
        },

        // Locker post

        {
          path: LOCKER_POST_LIST_PATH,
          component: AdminLockerPostList,
        },
        {
          path: LOCKER_POST_CREATE_PATH,
          component: AdminLockerpostCreate,
        },
        {
          path: LOCKER_POST_ITEM_PATH,
          component: AdminLockerPostItem,
        },

        // Postcodes.

        {
          path: POSTCODES_ITEM_PATH,
          component: AdminPostcodesItem,
        },
        {
          path: POSTCODES_LIST_PATH,
          component: AdminPostcodesList,
        },
        {
          path: POSTCODES_CREATE_PATH,
          component: AdminPostcodesCreate,
        },

        {
          path: ORDERS_PER_POSTCODES_PATH,
          component: AdminOrdersPerPostcodesContainer,
        },
        {
          path: POSTCODES_COORDINATES_PATH,
          component: AdminPostcodesCoordinatesContainer,
        },

        // Timeslot.

        {
          path: TIMESLOT_LIST_PATH,
          component: AdminTimeSlotList,
        },

        {
          path: TIMESLOT_AVAILABILITY_LIST_PATH,
          component: AdminTimeslotAvailabilityList,
        },

        // Promo.

        {
          path: PROMO_CREATE_PATH,
          component: AdminPromoCreate,
        },

        {
          path: PROMO_LIST_PATH,
          component: AdminPromoList,
        },

        {
          path: PROMO_ITEM_PATH,
          component: AdminPromoItem,
        },

        // Address Book.

        {
          path: ADDRESS_BOOK_LIST_PATH,
          component: AdminAddressBookList,
        },
        {
          path: ADDRESS_BOOK_ITEM_PATH,
          component: AdminAddressBookItem,
        },
        {
          path: ADDRESS_BOOK_CREATE_PATH,
          component: AdminAddressBookCreate,
        },
        {
          path: ADDRESS_BOOK_LIST_NEW_PATH,
          component: AdminAddressBookListNew,
        },
        {
          path: ADDRESS_BOOK_ITEM_NEW_PATH,
          component: AdminAddressBookItemNew,
        },
        {
          path: ADDRESS_BOOK_CREATE_NEW_PATH,
          component: AdminAddressBookCreateNew,
        },

        // Post order
        {
          path: POST_CREATE_PATH,
          component: AdminPostCreate,
        },
        {
          path: POST_ITEM_PATH,
          component: AdminPostItem,
        },

        // Simple Post

        {
          path: SIMPLE_POST_CREATE_PATH,
          component: AdminSimplePostCreate,
        },
        {
          path: SINGLE_SIMPLE_POST_CREATE_PATH,
          component: AdminSingleSimplePostCreate,
        },
        {
          path: SAMPLE_ORDER_ITEM_PATH,
          component: AdminSimpleOrderItemWrapper,
        },
        {
          path: SAMPLE_ORDER_ITEM_LIST_PATH,
          component: AdminSimpleOrderItemList,
        },

        // Customer Feedbacks

        {
          path: CUSTOMER_FEEDBACK_LIST_PATH,
          component: AdminCustomerFeedbackList,
        },
        // Shop.

        {
          path: SHOP_CATEGORY_LIST_PATH,
          component: AdminShopCategoryList,
        },
        {
          path: SHOP_VENUE_LIST_PATH,
          component: AdminShopVenueList,
          onEnter: AdminShopVenueList.onEnter,
        },
        {
          path: SHOP_VENUE_CREATE_PATH,
          component: AdminShopVenueCreate,
        },
        {
          path: SHOP_VENUE_ITEM_PATH,
          component: AdminShopVenueItem,
        },
        {
          path: SHOP_MENU_LIST_PATH,
          component: AdminShopMenuList,
          onEnter: AdminShopMenuList.onEnter,
        },
        {
          path: SHOP_MENU_CREATE_PATH,
          component: AdminShopMenuCreate,
        },
        {
          path: SHOP_MENU_ITEM_PATH,
          component: AdminShopMenuItem,
        },
        {
          path: SHOP_VENUE_MENU_LIST_PATH,
          component: AdminVenueMenuList,
        },
        {
          path: SHOP_PRODUCT_LIST_PATH,
          component: AdminVenueProductList,
        },
        {
          path: SHOP_PRODUCT_CREATE_PATH,
          component: AdminVenueProductCreate,
        },
        {
          path: SHOP_PRODUCT_ITEM_PATH,
          component: AdminVenueProductItem,
        },
        {
          path: SHOP_CITY_LIST_PATH,
          component: AdminShopCityList,
        },
        {
          path: SHOP_ADDRESS_LIST_PATH,
          component: AdminVenueAddressList,
        },
        {
          path: SHOP_ADDRESS_CREATE_PATH,
          component: AdminVenueAddressCreate,
        },
        {
          path: SHOP_ADDRESS_ITEM_PATH,
          component: AdminVenueAddressItem,
        },

        // Package.
        {
          path: DEFAULT_PACKAGE_NEW_PATH,
          component: AdminDefaultPackageContainerNew,
        },

        // Settings.
        {
          path: SETTINGS_DASHBOARD_PATH,
          component: AdminSettingsDashboardContainer,
        },
        {
          path: USERS_PERMISSION_LIST_PATH,
          component: AdminPermissionListContainer,
        },
        {
          path: USERS_PERMISSION_FORM_PATH,
          component: AdminPermissionsForm,
        },
        {
          path: SETTINGS_ORDER_PATH,
          component: AdminSettingsOrderContainer,
        },
        {
          path: ORDER_RULE_ITEM_PATH,
          component: AdminOrderRuleItemContainer,
        },
        {
          path: ORDER_RULE_LIST_PATH,
          component: AdminOrderRuleListContainer,
        },
        {
          path: ORDER_RULE_CREATE_PATH,
          component: AdminOrderRuleCreateContainer,
        },
        {
          path: PACKAGE_RULE_ITEM_PATH,
          component: AdminPackageRuleItemContainer,
        },
        {
          path: PACKAGE_RULE_LIST_PATH,
          component: AdminPackageRuleListContainer,
        },
        {
          path: PACKAGE_RULE_CREATE_PATH,
          component: AdminPackageRuleCreateContainer,
        },
        {
          path: ORDER_RULES_GROUP_LIST_PATH,
          component: AdminOrderRulesGroupListContainer,
        },
        {
          path: ORDER_RULES_GROUP_ITEM_PATH,
          component: AdminOrderRulesGroupItemContainer,
        },

        {
          path: COMMUNICATION_SETTINGS_LIST_PATH,
          component: AdminCommunicationSettingsList,
        },
        {
          path: COMMUNICATION_SETTINGS_CREATE_PATH,
          component: AdminCommunicationSettingsCreate,
        },
        {
          path: COMMUNICATION_SETTINGS_ITEM_PATH,
          component: AdminCommunicationSettingsItem,
        },
        {
          path: SUPPLIER_FINANCE_SETTINGS_PATH,
          component: AdminSupplierFinanceSettingsContainer,
        },
        {
          path: SCORE_SETTINGS_PATH,
          component: AdminScoreSettingsContainer,
        },
        {
          path: HOLIDAY_SETTINGS_PATH,
          component: AdminHolidaySettingsContainer,
        },
        {
          path: CALL_STEP_SETTINGS_LIST_PATH,
          component: AdminCallStepSettingsList,
        },
        {
          path: SETTINGS_MARKETPLACES_PATH,
          component: AdminMarketplaceListContainer,
        },
        {
          path: SETTINGS_SMS_PROVIDER_PATH,
          component: SMSSettings,
        },
        {
          path: SETTINGS_MAP_PATH,
          component: AdminMapSettingsContainer,
        },
        {
          path: SETTINGS_MARKETPLACE_DETAILS_URL,
          component: AdminMarketplaceDetailsContainer,
        },
        {
          path: SETTINGS_MARKETPLACE_ITEM_PATH,
          component: AdminMarketplaceItem,
        },
        {
          path: SETTINGS_PRICING_PLANS_PATH,
          component: AdminPricingPlansListContainer,
        },
        {
          path: SETTINGS_PRICING_PLANS_CREATE_PATH,
          component: AdminPricingPlanCreateContainer,
        },
        {
          path: SETTINGS_PRICING_PLANS_ITEM_PATH,
          component: AdminPricingPlanItemContainer,
        },
        {
          path: SLA_RULE_LIST_PATH,
          component: AdminSlaManagementRuleListContainer,
        },
        {
          path: SLA_RULE_CREATE_PATH,
          component: AdminSlaManagementRuleCreateContainer,
        },
        {
          path: SLA_RULE_ITEM_PATH,
          component: AdminSlaManagementRuleItemContainer,
        },
        {
          path: PDF_SETTINGS_PATH,
          component: AdminPdfSettingsList,
        },
        {
          path: PDF_TEMPLATE_CREATE_PATH,
          component: AdminPdfTemplateCreate,
        },
        {
          path: PDF_TEMPLATE_ITEM_PATH,
          component: AdminPdfTemplateItem,
        },
        {
          path: PDF_TEMPLATE_CREATE_HTML_PATH,
          component: AdminHtmlPdfTemplateCreate,
        },
        {
          path: PDF_TEMPLATE_HTML_ITEM_PATH,
          component: AdminHtmlPdfTemplateItem,
        },

        // Reporting

        {
          path: REPORTING_ROOT_PATH,
          component: AdminReportContainer,
        },
        {
          path: REPORTING_OPERATIONAL_PATH,
          component: AdminOperationalReportContainer,
        },
        {
          path: REPORTING_FINANCIAL_PATH,
          component: AdminFinanceReportContainer,
        },

        // Service Types
        {
          path: SERVICE_TYPES_LIST_PATH,
          component: AdminServiceTypesListContainer,
        },

        // Terms Setting
        {
          path: TERMS_SETTINGS_LIST_PATH,
          component: AdminTermsSettingsList,
        },

        {
          path: TERMS_SETTINGS_CREATE_PATH,
          component: AdminTermsCreateDialogWrapper,
        },
        {
          path: TERMS_SETTINGS_ITEM_PATH,
          component: AdminTermsEditDialogWrapper,
        },
        {
          path: ORDER_ACTIVITY_HISTORY_PATH,
          component: AdminActivityHistoryListContainer,
        },
        {
          path: ADDITIONAL_SERVICES_SETTINGS_LIST_PATH,
          component: AdminAdditionalServicesList,
        },

        {
          path: ADDITIONAL_SERVICES_SETTINGS_CREATE_PATH,
          component: AdditionalServiceAddWrapper,
        },
        {
          path: ADDITIONAL_SERVICES_SETTINGS_ITEM_PATH,
          component: AdditionalServiceEditWrapper,
        },
        {
          path: BATCH_CATEGORIES_LIST_PATH,
          component: AdminBatchCategoriesList,
        },
        {
          path: OPIS_CATEGORIES_LIST_PATH,
          component: AdminProductCategoriesList,
        },
        {
          component: ReportWarehouse,
          path: REPORT_WAREHOUSE_PATH,
        },
        {
          component: HybridContainer,
          path: HYBRID_PATH,
        },
        {
          component: AdminBatchHistoryUpdateList,
          path: CUSTOM_BATCH_UPDATE_PATH,
        },
        {
          component: AdminBatchHistoryUpdateNewList,
          path: NEW_CUSTOM_BATCH_UPDATE_PATH,
        },
        {
          component: AdminShiftingBatchesList,
          path: SHIFTING_HISTORY_PATH,
        },
        {
          component: AdminIpsBatchSyncList,
          path: IPS_BATCH_SYNC_PATH,
        },
        {
          component: AdminActManagementContainer,
          path: ACT_MANAGEMENT_PATH,
        },
        {
          component: ActManagementLocalCreateContainer,
          path: ACT_MANAGEMENT_LOCAL_CREATE_PATH,
        },
        {
          component: AdminIPSLettersList,
          path: LETTERS_PATH,
        },
        {
          component: AdminIPSPi1List,
          path: PI1_PATH,
        },

        {
          component: AdminDispatchItemList,
          path: DISPATCH_ITEM_PATH,
        },
        {
          component: AdminReceptacleCreateWrapper,
          path: RECEPTACLE_ITEM_PATH,
        },

        // international

        {
          component: AdminInternationalSettingsContainer,
          path: INTERNATIONAL_SETTINGS_PATH,
        },
        {
          component: AdminInternationalSettingsCountriesContainer,
          path: INTERNATIONAL_SETTINGS_COUNTRIES_PATH,
        },
        {
          component: AdminInternationalSettingsFormatContainer,
          path: INTERNATIONAL_SETTINGS_FORMAT_PATH,
        },
        {
          component: AdminInternationalSettingsSubclassesContainer,
          path: INTERNATIONAL_SETTINGS_SUB_CLASSES_PATH,
        },
        {
          component: AdminInternationalSettingsFlightsContainer,
          path: INTERNATIONAL_SETTINGS_FLIGHTS_PATH,
        },
        {
          component: AdminInternationalSettingsMailClassContainer,
          path: INTERNATIONAL_SETTINGS_MAIL_CLASS_PATH,
        },
        {
          component: AdminInternationalAirportContainer,
          path: INTERNATIONAL_SETTINGS_AIRPORT_PATH,
        },
        {
          component: AdminInternationalSettingsTypeContainer,
          path: INTERNATIONAL_SETTINGS_TYPES_PATH,
        },
        {
          component: AdminInternationalLetterContainer,
          path: INTERNATIONAL_LETTER_PATH,
        },
        {
          component: AdminInboundPackageContainer,
          path: INTERNATIONAL_IN_TARIFF_PATH,
        },
        {
          component: AdminInboundTariffCreateContainer,
          path: INTERNATIONAL_IN_TARIFF_CREATE_PATH,
        },
        {
          component: AdminInboundTariffUpdateContainer,
          path: INTERNATIONAL_IN_TARIFF_ITEM_PATH,
        },
        {
          component: AdminOutboundTariffContainer,
          path: INTERNATIONAL_OUT_TARIFF_PATH,
        },
        {
          component: AdminOutboundTariffCreateContainer,
          path: INTERNATIONAL_OUT_TARIFF_CREATE_PATH,
        },
        {
          component: AdminOutboundTariffUpdateContainer,
          path: INTERNATIONAL_OUT_TARIFF_ITEM_PATH,
        },
        {
          component: AdminInternationalParcelContainer,
          path: INTERNATIONAL_PARCEL_PATH,
        },
        {
          component: AdminInternationalEmptyBagsContainer,
          path: INTERNATIONAL_EMPTY_BAGS_PATH,
        },
        {
          component: AdminCustomsPaymentListContainer,
          path: ORDER_CUSTOMS_FEE_MANAGEMENT_LIST_VIEW_PATH,
        },
        {
          component: AdminCN38ReportsListContainer,
          path: REPORTS_CN_38_PATH,
        },
        {
          component: AdminCN38ReportsCreateContainer,
          path: REPORTS_CREATE_CN_38_PATH,
        },
        {
          component: AdminCN38ReportsItemContainer,
          path: REPORTS_EDIT_CN_38_PATH,
        },
        {
          component: AdminInboundCustomsCheckoutListContainer,
          path: CUSTOMS_CHECKOUT_PATH,
        },
      ],
    },

    // Not Found Handler.

    { path: "*", component: NotFound },
  ],
};
